import { Button } from "../../Button";
import { Headline } from "./Headline";
import { useElementConfig } from "../../context/StageElementContext";
import {
    StageElementGroupEditWrapper,
    StageElementTextEditWrapper,
} from "../StageElement";
import classNames from "classnames";

export const Hero = ({
    title,
    titleSize = "extraLarge",
    text,
    textAlign = "left",
    image,
    imagePosition = "right",
    buttonText,
    buttonLink,
    buttonAlign,
}) => {
    const elementConfig = useElementConfig();

    return (
        <div className="grid grid-cols-8 gap-4 lg:gap-6 items-center">
            <div
                className={classNames("col-span-5 flex flex-col", {
                    "order-2": imagePosition === "left",
                    "order-1": imagePosition === "right",
                })}
            >
                <Headline
                    className={classNames("sm:w-3/4 mb-1", {
                        "text-left": textAlign === "left",
                        "text-right ml-auto": textAlign === "right",
                        "text-center mx-auto": textAlign === "center",
                    })}
                    size={titleSize}
                    title={title}
                />
                <pre
                    className={classNames("sm:w-3/4 text-secondary mb-4", {
                        "text-left": textAlign === "left",
                        "text-right ml-auto": textAlign === "right",
                        "text-center mx-auto": textAlign === "center",
                    })}
                >
                    <StageElementTextEditWrapper argKey="text">
                        {text}
                    </StageElementTextEditWrapper>
                </pre>
                {!!buttonText && !!buttonLink && (
                    <div
                        className={classNames({
                            "self-start": buttonAlign === "left",
                            "self-end": buttonAlign === "right",
                            "self-center": buttonAlign === "center",
                        })}
                    >
                        <StageElementGroupEditWrapper
                            title="title.button"
                            args={{
                                buttonAlign:
                                    elementConfig?.arguments?.buttonAlign,
                                buttonText:
                                    elementConfig?.arguments?.buttonText,
                                buttonLink:
                                    elementConfig?.arguments?.buttonLink,
                            }}
                        >
                            <a href={buttonLink} target="_blank">
                                <Button>{buttonText}</Button>
                            </a>
                        </StageElementGroupEditWrapper>
                    </div>
                )}
            </div>
            {!!image && (
                <div
                    className={classNames("col-span-3", {
                        "order-1": imagePosition === "left",
                        "order-2": imagePosition === "right",
                    })}
                >
                    <img src={image.blob || image} alt="Hero image" />
                </div>
            )}
        </div>
    );
};
