import { Button } from "../../Button";
import {
    useElementConfig,
    useStageElementEditState,
} from "../../context/StageElementContext";
import { AddMVPElementButton } from "../controls/MVP";
import { StageElementGroupEditWrapper } from "../StageElement";

export const Links = ({ links }) => {
    const isEditing = useStageElementEditState();
    const elementConfig = useElementConfig();

    const renderLinks = (links || []).map((v, index) => {
        return (
            <StageElementGroupEditWrapper
                key={`stage-links-item-${v.buttonUrl}-${index}`}
                className="w-full"
                title="title.link"
                mvpIndex={index}
                mvpKey="links"
                args={elementConfig?.arguments?.links?.arguments}
            >
                <a href={v.link} target="_blank" className="block">
                    <Button variant="secondary" className="w-full" truncate>
                        {v.text}
                    </Button>
                </a>
            </StageElementGroupEditWrapper>
        );
    });

    return (
        <div className="grow space-y-2">
            {renderLinks}
            {isEditing && <AddMVPElementButton mvpKey="links" />}
        </div>
    );
};
