export const stageEditorContextReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_CURRENT_ELEMENT":
            return {
                ...prevState,
                currentElement: action.payload,
            };
        case "SET_IS_SETTINGS_OPEN":
            return {
                ...prevState,
                isSettingsOpen: action.payload,
            };
        case "SET_VALUE":
            return {
                ...prevState,
                value: action.payload,
            };
        case "CREATE_ELEMENT": {
            return {
                ...prevState,
                value: [...(prevState.value || []), action.payload],
            };
        }
        case "UPDATE_ELEMENT": {
            let updatedValue = (prevState.value || []).map((v) => {
                if (v.id === action.payload.id) {
                    return action.payload;
                }

                return v;
            });

            return {
                ...prevState,
                value: updatedValue,
            };
        }
        case "DELETE_ELEMENT": {
            let updatedValue = (prevState.value || []).filter(
                (v) => v.id !== action.payload.id
            );

            return {
                ...prevState,
                value: updatedValue,
            };
        }
        default: {
            return prevState;
        }
    }
};
