import { faAnchor } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import { stageStudioElements } from "lib/src/stage/elements";
import { Button } from "../Button";
import { useContext } from "react";
import { StageEditorContext } from "./StageEditorContext";
import classNames from "classnames";

export const StageEditorSidebar = ({
    onClose = () => {},
    onChange,
    value = [],
    className,
}) => {
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const { t } = useTranslation();

    const renderElementButtons = Object.keys(stageStudioElements).map((key) => {
        let elementConfig = stageStudioElements[key];

        const nextId =
            value.length > 0 ? Math.max(...value.map((v) => v.id)) + 1 : 0;
        const nextSort =
            value.length > 0 ? Math.max(...value.map((v) => v.sort)) + 1 : 1;

        const newItem = {
            id: nextId,
            type: key,
            sort: nextSort,
            props: elementConfig.defaultProps || {},
        };

        return (
            <StageEditorSidebarElementButton
                type={key}
                config={elementConfig}
                wrapperClassName="inline-block mr-2 mb-2"
                onClick={() => {
                    dispatch({ type: "CREATE_ELEMENT", payload: newItem });
                }}
            />
        );
    });

    return (
        <>
            <div
                className={classNames(
                    "transition-all rounded-lg space-y-2",
                    className
                )}
            >
                <div className="block">{renderElementButtons}</div>
            </div>
        </>
    );
};

const StageEditorSidebarElementButton = ({
    type,
    config,
    onClick,
    className,
    wrapperClassName,
}) => {
    const { t } = useTranslation();
    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: "sidebar-element",
        item: {
            type,
            ...config,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const opacity = isDragging ? 0.4 : 1;

    if (!config) {
        return null;
    }

    return (
        <div ref={dragRef} style={{ opacity }} className={wrapperClassName}>
            <Button
                variant="secondary"
                icon={faAnchor}
                grow
                iconProps={{ size: "md", className: "mr-1" }}
                className={classNames("w-full", className)}
                onClick={onClick}
            >
                <div className="flex items-center">
                    <div className="text-left">
                        <span>{t(config.label)}</span>
                    </div>
                </div>
            </Button>
        </div>
    );
};
