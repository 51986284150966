import { createContext, useEffect, useReducer } from "react";
import { stageEditorContextReducer } from "./StageEditorContext.reducer";
import { FooterMenu } from "../FooterMenu";
import { useTranslation } from "react-i18next";
import { StageEditorControls } from "./StageEditorControls";
import { Button } from "../Button";

const initialState = {
    currentElement: null,
    isSettingsOpen: false,
};

export const StageEditorContext = createContext();

export const StageEditorContextProvider = ({ value, onChange, children }) => {
    const { t } = useTranslation();

    const [store, dispatch] = useReducer(stageEditorContextReducer, {
        ...initialState,
        value,
    });

    useEffect(() => {
        if (onChange) {
            onChange(store.value);
        }
    }, [store.value, onChange]);

    const handleChange = (key, value) => {
        if (!store.currentElement) {
            return;
        }

        let updatedValue = JSON.parse(
            JSON.stringify(store.currentElement.value)
        );

        if (store.currentElement.mvpKey) {
            updatedValue.props[store.currentElement.mvpKey][
                store.currentElement.mvpIndex
            ][key] = value;
        } else {
            updatedValue.props[key] = value;
        }

        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: { ...store.currentElement, value: updatedValue },
        });
    };

    const handleSave = () => {
        dispatch({
            type: "UPDATE_ELEMENT",
            payload: store.currentElement.value,
        });

        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: null,
        });
    };

    const handleCancel = () => {
        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: null,
        });
    };

    return (
        <StageEditorContext.Provider value={[store, dispatch]}>
            {children}
            <FooterMenu
                open={!!store.currentElement}
                onClose={() =>
                    dispatch({ type: "SET_CURRENT_ELEMENT", payload: null })
                }
                title={t("title.edit_x", {
                    x: t(store.currentElement?.title),
                })}
                buttons={
                    <>
                        <Button variant="tertiary" onClick={handleCancel}>
                            {t("button.cancel")}
                        </Button>
                        <Button onClick={handleSave}>{t("button.save")}</Button>
                    </>
                }
            >
                <StageEditorControls
                    elementConfig={store.currentElement}
                    value={
                        store.currentElement?.mvpKey
                            ? store.currentElement?.value?.props[
                                  store.currentElement?.mvpKey
                              ][store.currentElement?.mvpIndex]
                            : store.currentElement?.value
                    }
                    onChange={handleChange}
                    disableGroups
                />
            </FooterMenu>
        </StageEditorContext.Provider>
    );
};
