import { useEffect, useState } from "react";
import { motion, animate } from "framer-motion";
import { PriceFormatter } from "../helpers/number.helper";

export const CountUp = ({
    value,
    startPercentage = 0.997,
    incrementPercentage = 0.05,
    updateInterval = 5000,
    className,
    format = true,
    animate: shouldAnimate = true,
}) => {
    const [animatedValue, setAnimatedValue] = useState(0);

    useEffect(() => {
        setAnimatedValue(value * startPercentage);
    }, [value, startPercentage]);

    useEffect(() => {
        if (!shouldAnimate) {
            return;
        }

        const updateValue = () => {
            const newValue =
                animatedValue + (value - animatedValue) * incrementPercentage;

            animate(animatedValue, newValue, {
                duration: 4,
                onUpdate: (value) => setAnimatedValue(value),
                ease: "easeInOut",
            });
        };

        const interval = setInterval(updateValue, updateInterval);

        return () => clearInterval(interval);
    }, [value, animatedValue, incrementPercentage, updateInterval]);

    let displayValue = shouldAnimate ? animatedValue : value;

    if (format) {
        displayValue = PriceFormatter.format(displayValue);
    }

    return (
        <motion.span
            className={className}
            transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatDelay: 4.5,
            }}
        >
            {displayValue}
        </motion.span>
    );
};
