import { Button } from "./Button";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import { Heading } from "./Heading";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { Card } from "./Card";

export const DialogFullscreen = ({
    children,
    className,
    open,
    onClose,
    title,
    subtitle,
    size = "small",
    cardClassName,
    ...props
}) => {
    const wrapperRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (wrapperRef.current) {
                wrapperRef.current.scrollTop = 0;
            }
        }, [100]);
    }, [wrapperRef, open]);

    if (!open) {
        return null;
    }

    return (
        <div className="fixed w-full h-full top-0 left-0 z-[2000] bg-fullscreen animate-appear">
            <div className="absolute h-full w-full pointer-events-none ">
                <div
                    className={classNames(
                        " w-full h-full rounded-lg drop-shadow-2xl max-h-full flex flex-col pointer-events-auto"
                    )}
                >
                    {(!!title || !!onClose) && (
                        <div className="border-b border-card-border bg-bg-black text-lg font-bold xl:pt-8">
                            <div className="max-w-screen-xl mx-auto p-4">
                                <Heading
                                    title={title}
                                    subtitle={subtitle}
                                    rightContent={
                                        !!onClose && (
                                            <Button
                                                className="bg-transparent text-black !p-1 !px-3"
                                                variant="tertiary"
                                                onClick={onClose}
                                            >
                                                <Icon
                                                    icon={faClose}
                                                    className="text-inherit"
                                                />
                                            </Button>
                                        )
                                    }
                                    className={"!mb-0"}
                                />
                            </div>
                        </div>
                    )}
                    <div
                        className="p-4 overflow-y-auto max-w-screen-xl w-full mx-auto h-full"
                        ref={wrapperRef}
                    >
                        <Card className={classNames("!static", cardClassName)}>
                            {children}
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
