import classNames from "classnames";
import { cloneElement, useEffect, useRef, useState } from "react";

export const Popover = ({
    button,
    children,
    className,
    wrapperClassName,
    collapsed: collapsedProp,
}) => {
    const popoverRef = useRef();
    const popoverButtonRef = useRef();
    const [collapsed, setCollapsed] = useState(collapsedProp || true);

    useEffect(() => {
        setCollapsed(collapsedProp || true);
    }, [collapsedProp]);

    useEffect(() => {
        const handleWindowClick = (e) => {
            if (
                !popoverButtonRef.current?.contains(e.target) &&
                !e.target.contains(popoverRef.current)
            ) {
                setCollapsed(true);
            }
        };

        window.addEventListener("click", handleWindowClick);

        return () => window.removeEventListener("click", handleWindowClick);
    }, []);

    return (
        <div className={classNames("relative", wrapperClassName)}>
            <div className="relative" ref={popoverButtonRef}>
                {cloneElement(button, {
                    onClick: () => setCollapsed(!collapsed),
                    className: classNames(
                        "cursor-pointer",
                        button.props?.className
                    ),
                })}
            </div>
            {!collapsed && (
                <div
                    className={classNames(
                        "absolute right-0 mt-1 w-40 drop-shadow-xl rounded-xl z-30 bg-black p-2",
                        className
                    )}
                    ref={popoverRef}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
