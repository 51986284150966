export const stageElementContextReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_IS_EDITING":
            return {
                ...prevState,
                isEditing: action.payload,
            };
        case "SET_IS_EDITOR":
            return {
                ...prevState,
                isEditor: action.payload,
            };
        case "SET_VALUE":
            return {
                ...prevState,
                value: action.payload,
            };
        default: {
            return prevState;
        }
    }
};
