import { useState } from "react";
import { Textbox } from "./Textbox";
import { Icon } from "./Icon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CheckButton } from "./CheckButton";
import { useTranslation } from "react-i18next";

export const Select = ({
    options,
    value,
    onChange,
    label,
    error,
    max,
    isLoading,
    className,
    multi = false,
    size = "medium",
    searchable,
    sort,
}) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState("");

    const handleChange = (option) => {
        if (multi) {
            if (value.includes(option.value)) {
                onChange(value.filter((v) => v !== option.value));
            } else {
                if (max !== undefined && value.length > max - 1) {
                    return;
                }

                onChange([...value, option.value]);
            }
        } else {
            onChange(option.value);
        }
    };

    let filteredOptions = (options || []).filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase())
    );

    if (sort) {
        filteredOptions.sort((a, b) => a.label.localeCompare(b.label));
    }

    const renderOptions = filteredOptions.map((option) => {
        let isActive = value.includes(option.value) || option.value === value;
        let isMaxedOut = multi && value?.length >= max;

        return (
            <CheckButton
                key={`multi-select-option-${option.value}`}
                value={isActive}
                onClick={() => handleChange(option)}
                className="snap-start mb-1 last:mb-0"
                size={size}
            >
                {isMaxedOut ? (
                    <span
                        className={isActive ? "text-cash" : "text-neutral-500"}
                    >
                        {option.label}
                    </span>
                ) : (
                    option.label
                )}
            </CheckButton>
        );
    });

    const renderButtons =
        multi &&
        (value || []).map((v) => {
            const option = options.find((o) => o.value === v);

            return (
                <div
                    className="py-1 px-2 bg-cash inline-flex items-center rounded-md mr-2 mb-2 cursor-pointer"
                    onClick={() => handleChange({ value: v })}
                >
                    <span className="mr-2 text-black font-semibold">
                        {option?.label}
                    </span>
                    <Icon icon={faTimes} size="xs" className="!text-black" />
                </div>
            );
        });

    return (
        <div className={className}>
            {!isLoading && multi && (
                <div className="whitespace-normal mb-2">{renderButtons}</div>
            )}
            {searchable && (
                <Textbox
                    label={label}
                    placeholder={
                        isLoading
                            ? t("placeholder.loading")
                            : t("placeholder.search")
                    }
                    disabled={isLoading}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="mb-2"
                    error={error}
                />
            )}
            {!isLoading && (
                <div>
                    <div className="overflow-auto rounded-lg max-h-[355px] snap-y snap-mandatory">
                        {renderOptions}
                    </div>
                </div>
            )}
        </div>
    );
};
