import { Label } from "../Label";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Textbox } from "../Textbox";
import { Textarea } from "../Textarea";
import { Select } from "../Select";
import { ImageInput } from "../ImageInput";
import { MVP } from "./controls/MVP";
import { Collapsible } from "../Collapsible";

export const StageEditorControls = ({
    value,
    onChange,
    className,
    elementConfig,
    index,
    disableGroups,
    ...props
}) => {
    const { t } = useTranslation();

    if (!elementConfig) {
        return null;
    }

    const groupedArguments = {};

    Object.keys(elementConfig.arguments || []).forEach((key) => {
        let argConfig = elementConfig.arguments[key];

        let group = argConfig.group;

        if (disableGroups) {
            group = "";
        }

        if (!groupedArguments[t(group)]) {
            groupedArguments[t(group)] = {
                [key]: argConfig,
            };
        } else {
            groupedArguments[t(group)][key] = argConfig;
        }
    });

    const renderControls = (args) =>
        Object.keys(args || []).map((key) => {
            let argConfig = args[key];
            let fieldValue = value.props?.[key] || value[key];

            return (
                <StageEditorControl
                    argConfig={argConfig}
                    value={fieldValue}
                    onChange={(v) => onChange(key, v)}
                    arg={key}
                />
            );
        });

    const renderGroups = Object.entries(groupedArguments)
        .filter(([key]) => !!key)
        .map(([key, entry]) => (
            <StageEditorControlGroup title={key}>
                {renderControls(entry)}
            </StageEditorControlGroup>
        ));

    return (
        <div className={classNames("grid gap-4", className)} {...props}>
            {renderControls(groupedArguments[""])}
            {renderGroups}
        </div>
    );
};

const StageEditorControlGroup = ({
    title,
    children,
    className,
    rightContent,
    ...props
}) => {
    return (
        <Collapsible
            title={title}
            size="small"
            rightContent={rightContent}
            {...props}
        >
            {children}
        </Collapsible>
    );
};

export const StageEditorControl = ({
    argConfig,
    className,
    onChange,
    value,
    ...props
}) => {
    const { t } = useTranslation();

    if (!argConfig) {
        console.error("argConfig is missing");

        return null;
    }

    return (
        <div className={classNames("space-y-2", className)} {...props}>
            {argConfig.type !== "mvp" && (
                <Label className="text-sm block">{t(argConfig.label)}</Label>
            )}
            <StageEditorControlInput
                argConfig={argConfig}
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

const StageEditorControlInput = ({
    argConfig,
    onChange = () => {},
    className,
    value,
    arg,
    ...props
}) => {
    const { t } = useTranslation();

    if (!argConfig) {
        console.error("argConfig is missing");

        return null;
    }

    let InputComponent = null;

    switch (argConfig.type) {
        case "text": {
            InputComponent = (
                <Textbox
                    type="text"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    maxLength={argConfig.maxLength}
                    {...props}
                />
            );

            break;
        }
        case "markdown": {
            InputComponent = (
                <Textarea
                    type="text"
                    onChange={(e) => onChange(e.target.value)}
                    inputClassName="min-h-32"
                    value={value}
                    maxLength={argConfig.maxLength}
                    {...props}
                />
            );

            break;
        }
        case "select": {
            InputComponent = (
                <Select
                    options={Object.entries(argConfig.options || {}).map(
                        ([key, entry]) => ({ value: key, label: t(entry) })
                    )}
                    onChange={(v) => onChange(v)}
                    value={[value]}
                    size="small"
                    searchable={argConfig.searchable}
                />
            );

            break;
        }
        case "image": {
            InputComponent = (
                <ImageInput
                    onChange={(v) => onChange(v)}
                    value={value}
                    size="small"
                />
            );

            break;
        }
        case "mvp": {
            InputComponent = (
                <StageEditorControlGroup
                    title={t(argConfig.label)}
                    rightContent={`${value?.length} / ${argConfig.maxEntries}`}
                >
                    <MVP
                        value={value}
                        onChange={(v) => onChange(v)}
                        argConfig={argConfig}
                        arg={arg}
                    />
                </StageEditorControlGroup>
            );

            break;
        }
        default: {
            console.error(
                `No component found for control type ${argConfig.type}`
            );

            return null;
        }
    }

    return InputComponent;
};
