import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Headline } from "./Headline";
import { Button } from "../../Button";
import { Card } from "../../Card";
import { Price } from "../../Price";
import {
    useElementConfig,
    useStageElementEditState,
} from "../../context/StageElementContext";
import {
    StageElementTextEditWrapper,
    StageElementGroupEditWrapper,
} from "../StageElement";
import { AddMVPElementButton } from "../controls/MVP";

export const ProductSlider = ({
    title,
    titleSize,
    description,
    products = [],
}) => {
    const isEditing = useStageElementEditState();

    const renderProducts = products.map((v, index) => (
        <SwiperSlide
            className="!h-auto"
            key={`product-slider-item${title}-${index}`}
        >
            <ProductSliderProduct product={v} index={index} />
        </SwiperSlide>
    ));

    return (
        <div className="w-full overflow-hidden">
            <div className="space-y-4">
                <div>
                    <Headline title={title} size={titleSize} />
                    {!!description && (
                        <p className="text-secondary">{description}</p>
                    )}
                </div>
                <Swiper
                    className="!pb-10"
                    breakpoints={{
                        400: {
                            slidesPerView: 1.6,
                        },
                        640: {
                            slidesPerView: 2.6,
                        },
                        1024: {
                            slidesPerView: 3.6,
                        },
                    }}
                    spaceBetween={8}
                    slidesPerView={1.3}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    style={{
                        "--swiper-pagination-color": "#1CF683",
                        "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
                        "--swiper-pagination-bullet-inactive-opacity": "0.2",
                        "--swiper-pagination-bullet-size": "8px",
                        "--swiper-pagination-bullet-horizontal-gap": "4px",
                    }}
                >
                    {renderProducts}
                    {isEditing && (
                        <SwiperSlide className="!h-auto">
                            <AddMVPElementButton
                                className="w-full h-full"
                                mvpKey="products"
                            />
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    );
};

const ProductSliderProduct = ({ product, index, className }) => {
    const elementConfig = useElementConfig();

    if (!product) {
        return null;
    }

    return (
        <Card className="!p-1 h-full">
            <div className="flex flex-col space-y-2 p-2 h-full">
                <img
                    className="w-full"
                    src={product.image?.blob || product.image}
                    draggable={false}
                />
                <div className="grow">
                    <Headline
                        title={product.title}
                        size="small"
                        mvpIndex={index}
                        mvpKey="products"
                        className="mb-1"
                    />
                    <pre className="text-tertiary text-sm">
                        <StageElementTextEditWrapper
                            argKey="description"
                            mvpIndex={index}
                            mvpKey="products"
                        >
                            {product.description}
                        </StageElementTextEditWrapper>
                    </pre>
                </div>
                <StageElementGroupEditWrapper
                    mvpIndex={index}
                    mvpKey="products"
                    title="title.price"
                    args={{
                        price: elementConfig?.arguments?.products?.arguments
                            ?.price,
                        discount:
                            elementConfig?.arguments?.products?.arguments
                                ?.discount,
                    }}
                >
                    {!!product.price && (
                        <Price discount={product.discount}>
                            {product.price}
                        </Price>
                    )}
                </StageElementGroupEditWrapper>
                <StageElementGroupEditWrapper
                    title="title.button"
                    mvpIndex={index}
                    mvpKey="products"
                    args={{
                        buttonText:
                            elementConfig?.arguments?.products?.arguments
                                ?.buttonText,
                        buttonLink:
                            elementConfig?.arguments?.products?.arguments
                                ?.buttonLink,
                    }}
                >
                    {!!product.buttonText && product.buttonLink && (
                        <a
                            href={product.buttonLink}
                            target={
                                product.buttonLink === "#" ? "_self" : "_blank"
                            }
                        >
                            <Button size="small" className="w-full">
                                {product.buttonText}
                            </Button>
                        </a>
                    )}
                </StageElementGroupEditWrapper>
            </div>
        </Card>
    );
};
