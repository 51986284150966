import classNames from "classnames";
import { StageElementTextEditWrapper } from "../StageElement";

export const Headline = ({
    title,
    size = "medium",
    mvpKey,
    mvpIndex,
    className,
}) => {
    return (
        <div>
            <h2
                className={classNames(
                    "font-bold",
                    {
                        "text-sm": size === "extraSmall",
                        "text-base": size === "small",
                        "text-lg": size === "medium",
                        "text-xl": size === "large",
                        "text-xl md:text-2xl": size === "extraLarge",
                    },
                    className
                )}
            >
                <StageElementTextEditWrapper
                    argKey="title"
                    mvpKey={mvpKey}
                    mvpIndex={mvpIndex}
                    className={className}
                >
                    {title}
                </StageElementTextEditWrapper>
            </h2>
        </div>
    );
};
