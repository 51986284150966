import { StageElementTextEditWrapper } from "../StageElement";

export const Paragraph = ({ text }) => {
    return (
        <div>
            <pre className="!text-secondary">
                <StageElementTextEditWrapper argKey="text">
                    {text}
                </StageElementTextEditWrapper>
            </pre>
        </div>
    );
};
