import { createContext, useContext, useEffect, useReducer } from "react";
import { stageElementContextReducer } from "./StageElementContext.reducer";

export const StageElementContext = createContext();

export const StageElementContextProvider = ({
    children,
    elementConfig,
    isEditing,
    isEditor,
    value,
}) => {
    const [store, dispatch] = useReducer(stageElementContextReducer, {
        elementConfig,
        isEditing,
        isEditor,
        value,
    });

    useEffect(() => {
        dispatch({ type: "SET_IS_EDITING", payload: isEditing });
    }, [isEditing]);

    useEffect(() => {
        dispatch({ type: "SET_IS_EDITOR", payload: isEditor });
    }, [isEditor]);

    useEffect(() => {
        dispatch({ type: "SET_VALUE", payload: value });
    }, [value]);

    return (
        <StageElementContext.Provider value={[store, dispatch]}>
            {children}
        </StageElementContext.Provider>
    );
};

export const useElementConfig = () => {
    const [stageElementContext] = useContext(StageElementContext);

    return stageElementContext?.elementConfig;
};

export const useElementValue = () => {
    const [stageElementContext] = useContext(StageElementContext);

    return stageElementContext?.value;
};

export const useStageElementEditState = () => {
    const [stageElementContext] = useContext(StageElementContext);

    return stageElementContext?.isEditing;
};
