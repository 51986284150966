import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export const Icon = ({ className, icon, size, fixedWidth, ...props }) => {
    if (typeof icon === "string") {
        // Is an image

        return (
            <span
                classNames={classNames({
                    "min-w-[20px] text-center": fixedWidth,
                })}
            >
                <img
                    src={icon}
                    className={classNames(
                        {
                            "w-3": size === "sm",
                            "max-w-[20px] max-h-[20px]": !size,
                            "w-5": size === "lg",
                        },
                        className
                    )}
                    alt="Icon"
                />
            </span>
        );
    }

    return (
        <FontAwesomeIcon
            className={classNames("text-white", className)}
            icon={icon}
            size={size}
            fixedWidth={fixedWidth}
            {...props}
        />
    );
};
