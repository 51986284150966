import { cloneElement, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Heading } from "./Heading";

export const Navigator = ({
    children,
    title,
    subtitle,
    rightContent,
    className,
    ...props
}) => {
    const topRef = useRef();

    const tabs = (Array.isArray(children) ? children : []).filter(
        (child) => !!child?.props?.panelId
    );
    const panels = (Array.isArray(children) ? children : []).filter(
        (child) => !!child?.props?.id
    );

    const hashPanelId = window?.location?.hash
        ? window.location.hash.replace("#", "")
        : null;

    const [currentTab, setCurrentTab] = useState(
        hashPanelId || panels?.[0]?.props?.id || ""
    );

    if (!Array.isArray(children)) {
        console.error(
            "Children are not an array, provide NavigatorTab and NavigatorPanel"
        );
    }

    const handleTabChange = (panelId) => {
        setCurrentTab(panelId);

        window.location.hash = panelId;
    };

    useEffect(() => {
        if (window.location.hash) {
            const localHashPanelId = window.location.hash.replace("#", "");

            if (tabs.find((tab) => tab?.props?.panelId === localHashPanelId)) {
                setCurrentTab(localHashPanelId);
            }

            if (topRef.current) {
                topRef.current.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            setCurrentTab(panels?.[0]?.props?.id || "");
        }
    }, [window.location.hash]);

    const renderTabs = tabs.map((tab) => {
        if (!tab?.props?.panelId) {
            return null;
        }

        const clonedTab = cloneElement(tab, {
            ...tab.props,
            key: `navigator-tab-${tab.props.panelId}`,
            active: currentTab === tab?.props?.panelId,
            onClick: () => handleTabChange(tab?.props?.panelId),
        });

        return clonedTab;
    });

    const currentPanel = panels.find(
        (panel) => panel?.props?.id === currentTab
    );

    return (
        <div className={classNames("relative", className)} {...props}>
            <div ref={topRef} className="absolute -top-4" />
            {!!title && (
                <Heading
                    title={title}
                    subtitle={subtitle}
                    rightContent={rightContent}
                    className="mb-4"
                    size="medium"
                    tag="h3"
                />
            )}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="md:col-span-4">
                    <div className="grid gap-2 grid-cols-2 md:grid-cols-1 sticky top-4 z-10">
                        {renderTabs}
                    </div>
                </div>
                <div className="md:col-span-8">
                    {!!currentPanel && (
                        <div>
                            {currentPanel.props?.title && (
                                <Heading
                                    title={currentPanel?.props?.title}
                                    subtitle={currentPanel?.props?.subtitle}
                                    rightContent={
                                        currentPanel?.props?.rightContent
                                    }
                                    size="medium"
                                    tag="h4"
                                    className="mb-4"
                                />
                            )}
                            {currentPanel}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const NavigatorTab = ({
    children,
    onClick,
    active,
    rightContent,
    title,
    subtitle,
    panelId,
}) => {
    return (
        <div
            className={classNames(
                "w-full text-secondary font-bold rounded-md transition-all hover:text-primary cursor-pointer hover:bg-white/10 p-2 whitespace-nowrap truncate md:text-left text-center",
                {
                    "!text-primary bg-white/5": active,
                }
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export const NavigatorPanel = ({ children, id }) => {
    return <div id={id}>{children}</div>;
};
