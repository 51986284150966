import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Tab } from "../Tabs";
import { LanguageSelect } from "../selects/LanguageSelect";

export const LanguageTab = ({ ...props }) => {
    const { i18n } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const handleChange = (v) => {
        localStorage.setItem("ar-setting-language", v);

        dispatch({ type: "SET_LANGUAGE", payload: v });

        i18n.changeLanguage(v);
    };

    return (
        <Tab {...props}>
            <LanguageSelect
                value={userContext?.language}
                onChange={handleChange}
                label={null}
                searchable
            />
        </Tab>
    );
};
