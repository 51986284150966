import React, { useState } from "react";
import { Dialog } from "../components/Dialog";
import { Button } from "../components/Button";

const useQuestion = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogConfirmLabel, setDialogConfirmLabel] = useState("");
    const [dialogCancelLabel, setDialogCancelLabel] = useState("");
    const [buttonVariant, setButtonVariant] = useState("");
    const [resolveFn, setResolveFn] = useState(null);

    const question = async (
        title = "",
        text = "",
        confirmLabel = "Yes",
        cancelLabel = "No",
        variant = "primary"
    ) => {
        setDialogText(text);
        setDialogTitle(title);
        setDialogConfirmLabel(confirmLabel);
        setDialogCancelLabel(cancelLabel);
        setButtonVariant(variant);
        setIsOpen(true);

        return new Promise((resolve) => {
            setResolveFn(() => resolve);
        });
    };

    const handleConfirm = (confirmed) => {
        setIsOpen(false);

        if (resolveFn) {
            resolveFn(confirmed);
        }
    };

    const QuestionDialog = () => (
        <Dialog
            open={isOpen}
            onClose={() => handleConfirm(false)}
            title={dialogTitle}
        >
            <pre dangerouslySetInnerHTML={{ __html: dialogText }} />
            <div className="flex justify-end mt-4">
                <Button
                    variant="secondary"
                    className="mr-2"
                    onClick={() => handleConfirm(false)}
                >
                    {dialogCancelLabel}
                </Button>
                <Button
                    variant={buttonVariant}
                    onClick={() => handleConfirm(true)}
                >
                    {dialogConfirmLabel}
                </Button>
            </div>
        </Dialog>
    );

    return { question, QuestionDialog };
};

export default useQuestion;
