import { useContext, useRef, useState } from "react";
import { Hero } from "./elements/Hero";
import { Paragraph } from "./elements/Paragraph";
import classNames from "classnames";
import { StageEditorContext } from "./StageEditorContext";
import { Headline } from "./elements/Headline";
import { Icon } from "../Icon";
import {
    faCheck,
    faCheckDouble,
    faClose,
    faCog,
    faEllipsis,
    faGripVertical,
    faICursor,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useDrag } from "react-dnd";
import { Links } from "./elements/Links";
import { ProductSlider } from "./elements/ProductSlider";
import {
    StageElementContext,
    StageElementContextProvider,
    useElementConfig,
    useElementValue,
    useStageElementEditState,
} from "../context/StageElementContext";
import { getElementConfigFromType } from "lib/src/stage/elements";
import { Message } from "../Message";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Circle } from "../Circle";
import { Popover } from "../Popover";
import { IconButton } from "../IconButton";
import { AddMVPElementButton } from "./controls/MVP";
import useAutosizeTextArea from "../../hooks/useAutosizeTextArea";
import MDEditor, { commands } from "@uiw/react-md-editor";
import useQuestion from "../../hooks/useQuestion";

const keyElementMap = {
    "hero": Hero,
    "paragraph": Paragraph,
    "headline": Headline,
    "links": Links,
    "productSlider": ProductSlider,
};

export const StageElement = ({
    element,
    onClick,
    draggable,
    isEditor,
    isEditing,
    value,
    ...props
}) => {
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const { t } = useTranslation();
    const { QuestionDialog, question } = useQuestion();

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: "element",
            item: element,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [element]
    );

    if (!element) {
        return null;
    }

    const ElementComponent = keyElementMap[element.type];

    if (!ElementComponent) {
        console.error(`Element component ${element.type} not found`);

        return null;
    }

    const elementConfig = getElementConfigFromType(element.type);

    const renderAddButtons = Object.entries(elementConfig.arguments || {})
        .filter(([key, entry]) => entry.type === "mvp")
        .map(([key, entry]) => (
            <AddMVPElementButton
                variant="secondary"
                size="small"
                className="w-full px-3 py-2"
                rightIcon={faPlus}
                grow
                elementConfig={elementConfig}
                mvpKey={key}
                iconProps={{}}
            >
                {t("button.add_item")}
            </AddMVPElementButton>
        ));

    const handleDelete = async () => {
        if (
            !(await question(
                t("title.delete_stage_element"),
                t("question.delete_stage_element", {
                    element: `${t(elementConfig.label)} #${value.id}`,
                }),
                t("button.delete"),
                t("button.cancel"),
                "destructive"
            ))
        ) {
            return;
        }

        dispatch({ type: "DELETE_ELEMENT", payload: value });
    };

    const settingsArguments = Object.entries(
        elementConfig?.arguments || {}
    ).reduce(
        (prev, [key, entry]) =>
            entry?.inSettings ? { ...prev, [key]: entry } : prev,
        {}
    );

    const hasSettingsArguments = Object.keys(settingsArguments).length > 0;

    return (
        <StageElementContextProvider
            elementConfig={elementConfig}
            isEditing={isEditing}
            isEditor={isEditor}
            value={value}
        >
            <div
                className={classNames("relative grid items-center w-full", {
                    "grid-cols-[auto_1fr]": draggable,
                    "grid-cols-1": !draggable,
                })}
            >
                {draggable && (
                    <div ref={draggable && dragRef} className="cursor-move">
                        <Icon
                            icon={faGripVertical}
                            size="sm"
                            className="!text-tertiary mr-4 ml-1"
                        />
                    </div>
                )}
                <div
                    className={classNames({
                        "border-l-2 border-white/5 mb-4 pl-2": isEditing,
                    })}
                >
                    <div
                        className={classNames(
                            "relative grid items-center transition-all rounded-md",
                            {
                                "cursor-pointer hover:bg-white/5":
                                    !!onClick &&
                                    stageEditorContext.currentElement?.id !==
                                        element.id,
                                "bg-white/10":
                                    stageEditorContext.currentElement?.id ===
                                    element.id,
                                "opacity-40": isDragging,
                                "p-2": isEditor,
                            }
                        )}
                        onClick={() => !!onClick && onClick(element)}
                        {...props}
                    >
                        {isEditing && (
                            <div className="flex mb-2 items-center">
                                <span className="grow font-bold text-tertiary">
                                    {t(elementConfig.label)} #{element.id}
                                </span>
                                <Popover
                                    wrapperClassName="inline-block"
                                    button={
                                        <IconButton
                                            icon={faEllipsis}
                                            variant="secondary"
                                            size="medium"
                                        />
                                    }
                                    className="space-y-2"
                                >
                                    {hasSettingsArguments && (
                                        <Button
                                            variant="secondary"
                                            size="small"
                                            className="w-full px-3 py-2"
                                            rightIcon={faCog}
                                            grow
                                            onClick={() => {
                                                dispatch({
                                                    type: "SET_CURRENT_ELEMENT",
                                                    payload: {
                                                        ...elementConfig,
                                                        arguments:
                                                            settingsArguments,
                                                        title: t(
                                                            "title.edit_x",
                                                            {
                                                                x: t(
                                                                    elementConfig.label
                                                                ),
                                                            }
                                                        ),
                                                        value,
                                                    },
                                                });
                                            }}
                                        >
                                            {t("button.settings")}
                                        </Button>
                                    )}
                                    {renderAddButtons}
                                    <Button
                                        variant="destructive"
                                        size="small"
                                        className="w-full px-3 py-2"
                                        rightIcon={faTrash}
                                        grow
                                        onClick={handleDelete}
                                    >
                                        {t("button.delete")}
                                    </Button>
                                </Popover>
                            </div>
                        )}
                        <ElementComponent
                            {...(element.props || {})}
                            isEditing={isEditing}
                        />
                    </div>
                </div>
            </div>
            <QuestionDialog />
        </StageElementContextProvider>
    );
};

export const StageElementTextEditWrapper = ({
    children,
    argKey,
    className,
    mvpIndex,
    mvpKey,
    ...props
}) => {
    const [stageElementContext] = useContext(StageElementContext);
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const elementConfig = useElementConfig();
    const elementValue = useElementValue();
    const isEditing = useStageElementEditState();
    const [localTextValue, setLocalTextValue] = useState(
        elementValue?.props?.[mvpKey]?.[mvpIndex]?.[argKey] ||
            elementValue?.props?.[argKey]
    );
    const { t } = useTranslation();

    const [isFocused, setIsFocused] = useState(false);

    const textAreaRef = useRef(null);

    useAutosizeTextArea(textAreaRef.current, localTextValue);

    if (!elementConfig) {
        console.error(`Element config not found`);

        return null;
    }

    const argConfig =
        elementConfig?.arguments?.[mvpKey]?.arguments?.[argKey] ||
        elementConfig.arguments[argKey];

    if (!argConfig) {
        console.error(`Argument config for argument ${argKey} not found`);

        return null;
    }

    const handleChange = (e) => {
        let value = e?.target?.value || e;

        setLocalTextValue(value);
    };

    const handleAccept = () => {
        setIsFocused(false);

        let updatedValue = JSON.parse(JSON.stringify(elementValue));

        if (mvpKey) {
            updatedValue.props[mvpKey][mvpIndex][argKey] = localTextValue;
        } else {
            updatedValue.props[argKey] = localTextValue;
        }

        dispatch({
            type: "UPDATE_ELEMENT",
            payload: updatedValue,
        });
    };

    const handleKeyDown = (e) => {
        e.target.style.height = "inherit";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleCancel = () => {
        setIsFocused(false);

        setLocalTextValue(
            elementValue?.props?.[mvpKey]?.[mvpIndex]?.[argKey] ||
                elementValue?.props?.[argKey]
        );
    };

    return (
        <div
            {...props}
            className={classNames(
                "relative",
                {
                    "hover:bg-white/5 hover:rounded-md transition-all p-1":
                        stageElementContext.isEditing,
                    "rounded-md !bg-white/10": isFocused && isEditing,
                    "!cursor-pointer": stageElementContext.isEditor,
                },
                className
            )}
        >
            {stageElementContext.isEditing && !isFocused && (
                <div className="absolute top-1/2 -translate-y-1/2 right-2">
                    <Circle className="bg-black w-4 h-4 outline-black outline">
                        <Icon
                            icon={faICursor}
                            size="2xs"
                            className="!text-cash"
                        />
                    </Circle>
                </div>
            )}
            {isEditing ? (
                argConfig.type === "markdown" ? (
                    isFocused ? (
                        <MarkdownEditor
                            textareaProps={{
                                maxLength: argConfig.maxLength,
                                placeholder: t("placeholder.write"),
                            }}
                            value={localTextValue}
                            onChange={handleChange}
                            onAccept={handleAccept}
                            onFocus={() => setIsFocused(true)}
                            onClose={handleCancel}
                        />
                    ) : (
                        <div
                            onClick={() => setIsFocused(true)}
                            className="min-h-6"
                        >
                            <Markdown source={localTextValue} />
                        </div>
                    )
                ) : (
                    <textarea
                        ref={textAreaRef}
                        className={classNames(
                            "block w-full outline-0 text-inherit bg-transparent p-0 m-0 overflow-hidden resize-none",
                            className
                        )}
                        onChange={handleChange}
                        value={localTextValue}
                        onKeyDown={handleKeyDown}
                        onLoad={handleKeyDown}
                        rows={1}
                        maxLength={argConfig.maxLength}
                        onFocus={() => setIsFocused(true)}
                        onBlur={handleAccept}
                    />
                )
            ) : argConfig.type === "markdown" ? (
                <Markdown source={localTextValue} />
            ) : (
                children
            )}
        </div>
    );
};

const Markdown = ({ source }) => {
    return (
        <MDEditor.Markdown
            source={source}
            style={{ background: "transparent" }}
            className="!text-secondary"
            allowedElements={[
                "strong",
                "p",
                "ul",
                "ol",
                "li",
                "hr",
                "blockquote",
                "del",
                "em",
                // "table",
                // "tr",
                // "td",
                // "th",
                // "tbody",
                // "thead",
            ]}
        />
    );
};

export const StageElementGroupEditWrapper = ({
    children,
    args,
    title,
    mvpIndex,
    mvpKey,
    className,
    ...props
}) => {
    const [stageElementContext] = useContext(StageElementContext);
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const elementConfig = useElementConfig();
    const elementValue = useElementValue();
    const { t } = useTranslation();

    if (!elementConfig) {
        console.error(`Element config not found`);

        return null;
    }

    if (!Object.keys(args || {}).length) {
        return (
            <Message
                className="!p-2"
                variant="negative"
            >{`No arguments for group given`}</Message>
        );
    }

    const handleFooterMenuOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: {
                ...elementConfig,
                arguments: args,
                title,
                value: elementValue,
                mvpIndex,
                mvpKey,
            },
        });
    };

    return (
        <>
            <div
                {...props}
                className={classNames(
                    "outline-0 relative inline-block",
                    {
                        "!cursor-pointer": stageElementContext.isEditor,
                    },
                    className
                )}
                onClick={(e) =>
                    stageElementContext.isEditor && handleFooterMenuOpen(e)
                }
            >
                {stageElementContext.isEditing && (
                    <div className="absolute -top-2 -right-1 z-10">
                        <Circle className="bg-black outline outline-black w-4 h-4 transition-all">
                            <Icon
                                icon={faCog}
                                size="2xs"
                                className="!text-cash"
                            />
                        </Circle>
                    </div>
                )}
                {children}
            </div>
        </>
    );
};

export const MarkdownEditor = ({ onAccept, onClose, ...props }) => {
    return (
        <div className="relative">
            <MDEditor
                preview="edit"
                commands={[
                    commands.bold,
                    commands.italic,
                    commands.unorderedListCommand,
                    commands.orderedListCommand,
                    commands.divider,
                    commands.hr,
                    commands.quote,
                    commands.strikethrough,
                ]}
                extraCommands={
                    !!onClose
                        ? [
                              commands.group([], {
                                  name: "close",
                                  groupName: "close",
                                  icon: (
                                      <Icon
                                          icon={faClose}
                                          className="!text-secondary"
                                      />
                                  ),
                                  execute: onClose,
                                  buttonProps: {
                                      "aria-label": "Close",
                                  },
                              }),
                          ]
                        : []
                }
                style={{
                    background: "transparent",
                    border: "none",
                }}
                height="auto"
                {...props}
            />
            <div className="absolute right-2 bottom-2">
                <IconButton
                    onClick={onAccept}
                    icon={faCheck}
                    size="medium"
                    variant="secondaryActive"
                    iconProps={{ className: "!text-cash" }}
                />
            </div>
        </div>
    );
};
